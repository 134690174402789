import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import AppContext from "../../AppContext";
import {MainButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {errorAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import renderOverlay from "../../components/Overlay/renderOverlay";
import XlnzMenu from "../../components/XlnzMenu/XlnzMenu";
import Companyprop from "../../enums/Companyprop";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useCustomers from "../../hooks/useCustomers";
import useEmployees from "../../hooks/useEmployees";
import I18n from "../../I18n/I18n";
import {formatDate, formatTime, todayDate} from "../../utils/DateUtils";
import {formatAddress} from "../../utils/StringUtil";
import FormService from "../Forms/FormService";
import FormanswerEditForm from "./edit/FormanswerEditForm";
import FormanswerService from "./FormanswerService";

const SCAFFOLING_FORMS = [
    'AMV_SCAFFOLDING_PLAN',
    'STIB_FACTS_AND_SELF_MONITORING_SCAFFOLDING',
    'STIB_FACTS_AND_SELF_MONITORING_WEATHER_PROTECTION',
    'STIB_KONTROLL_STALLNING_19_PUNKT_12',
    'STIB_OVERLAMNING_STALLNINGSENTREPRENAD',
    'STIB_PROVDRAGNINGSPROTOKOLL',
    'STIB_RAMBESKRIVNING_STALLNINGSENTREPRENAD'
]

const FormanswerGrid = props => {
    const context = useContext(AppContext)
    const {
        tenderId,
        projectId,

        save,
        form,
        onLoad
    } = props

    if (tenderId === undefined && projectId === undefined) throw new Error("Need either tenderId or projectId.")

    const employees = useEmployees()
    const customers = useCustomers()

    const COMPANY_TYPE_SCAFFOLDER = useCompanyProp(Companyprop.COMPANY_TYPE_SCAFFOLDER, false)

    const filter = useFilter('FormGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    const [formList, setFormList] = useState();

    const menuRef = useRef()
    const menuItems = !formList ? undefined : formList
        .filter(item => COMPANY_TYPE_SCAFFOLDER || !SCAFFOLING_FORMS.includes(item.key))
        .map(item => ({
            label: item.name,
            command: () => {
                form.trigger().then(async isOk => {
                    if (isOk) {
                        showLoading()
                        const _model = await save(form.getValues())
                        // setProjectId(_model.id)

                        const newModel = {
                            id: 0,
                            company: context.currentCompany.id,
                            form: item,
                            rowList: []
                        }

                        if (tenderId !== undefined) newModel.tender = _model.id
                        if (projectId !== undefined) newModel.project = _model.id

                        console.log("_model", _model)

                        renderOverlay(<FormanswerEditForm
                            model={newModel}
                            onSave={onSave}
                            defaultFormValues={{
                                '$date': todayDate(),
                                '$companyCity': context.currentCompany.address.city,
                                '$projectAddressString': formatAddress(_model.address) || '',
                                '$customercontactName': customers.getContactName(_model.customer, _model.customercontact) || ''
                            }}
                        />)
                        hideLoading()
                    } else {
                        errorAlert(I18n.t('required_form_items_missing'))
                    }
                })
            }
        }))

    const loadFormanswers = useCallback(async (tId, pId) => {
        if (!tId && !pId) {
            errorAlert("loadFormanswers need either tenderId or projectId.")
            throw new Error("loadFormanswers need either tenderId or projectId.")
        }

        let _list
        showLoading()
        if (pId) _list = await FormanswerService.findByProject(pId)
        if (tId) _list = await FormanswerService.findByTender(tId)

        _list.forEach(item => {
            if (item.performedByEmployee) {
                item.performedByStr = item.finishTime
                    ? formatTime(item.finishTime) + ' av ' + employees.findById(item.performedByEmployee)?.name
                    : ''
            } else {
                item.performedByStr = item.finishTime ? formatDate(item.finishTime) : ''
            }
        })

        setList(_list)
        hideLoading()
    }, [employees])

    const loadData = useCallback(async () => {
        showLoading()
        setFormList(await FormService.findAll())
        if (tenderId > 0 || projectId > 0) {
            await loadFormanswers(tenderId, projectId)
        }
        hideLoading()
    }, [loadFormanswers, tenderId, projectId])

    useEffect(() => {
        loadData()
    }, [loadData, tenderId, projectId])

    function onRowSelect({data}) {
        console.log("ROOW", data)
        renderOverlay(<FormanswerEditForm model={data} onSave={onSave}/>)

    }

    useEffect(() => {
        onLoad({
            count: list.length
        })
    }, [list, onLoad]);

    async function onSave(savedModel) {
        await loadFormanswers(savedModel.tender, savedModel.project)
    }

    const leftFilters = [<div>
        <label className="label">&nbsp;</label>
        <XlnzMenu model={menuItems} ref={el => menuRef.current = el}/>
        <MainButton faicon={faPlus} onClick={e => menuRef.current.toggle(e)}/>
    </div>]

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.form.name,
            rowData => '/api/formanswers/' + rowData.id + '/pdf'
        ),
        {field: 'name', headerI18n: 'name'},
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 130},
        {
            field: 'showToEmployees', headerI18n: 'show_to_employees', body: GridTemplates.boolean, width: 110,
        },
        {
            field: 'fillable', headerI18n: 'fillable', body: GridTemplates.boolean, width: 90,
        },
        {field: 'performedByStr', headerI18n: 'performed'},
        {field: 'sentTime', headerI18n: 'sent', body: GridTemplates.time, width: 130},
    ];

    if (!formList) return;
    return <Grid
        scrollable={false}
        onRowSelect={onRowSelect}
        leftFilters={leftFilters}
        hideRightFilters={true}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
    />
}

export default FormanswerGrid;
