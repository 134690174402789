import {hideLoading, showLoading} from "../components/Overlay/Loading";
import NetUtils from "../utils/NetUtils";
import {validateBankgiro, validateSwedishRegNo} from "../utils/ValidateUtils";

export default function useLoadCompanyInfo() {

    async function loadCompanyInfo(props) {
        const {
            regNo,
            bankgiro
        } = props

        if (validateBankgiro(bankgiro)) {
            showLoading()
            const _companyInfo = await NetUtils.doGet("/api/company-info?bankgiro=" + bankgiro).then(res => res, () => undefined)
            hideLoading()
            return _companyInfo?.name ? _companyInfo : undefined

        } else if (validateSwedishRegNo(regNo)) {
            showLoading()
            const _companyInfo = await NetUtils.doGet("/api/company-info?regNo=" + regNo).then(res => res, () => undefined)
            hideLoading()
            return _companyInfo?.name ? _companyInfo : undefined
        }
    }

    return {
        findByRegNo: regNo => loadCompanyInfo({regNo: regNo}),
        findByBankgiro: bankgiro => loadCompanyInfo({bankgiro: bankgiro})
    }
}