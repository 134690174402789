import {groupBy} from "lodash";
import React, {useContext, useState} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {PdfViewerModal} from "../../../components/Appendix/PdfViewer";
import {CancelButton, PdfButton, SaveButton} from "../../../components/Buttons/Button";
import "../../../components/EditForm/EditForm.scss"
import FormItemAddress from "../../../components/EditForm/FormItemAddress";
import FormItemAppendixGrid from "../../../components/EditForm/FormItemAppendixGrid";
import FormItemCostcenter from "../../../components/EditForm/FormItemCostcenter";
import FormItemCustomerAndContact from "../../../components/EditForm/FormItemCustomerAndContact";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemInteger from "../../../components/EditForm/FormItemInteger";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemRowGrid from "../../../components/EditForm/FormItemRowGrid";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {useFilter} from "../../../components/Grid/filter";
import {isFreeRow} from "../../../components/Grid/freeRows";
import onRowChange from "../../../components/Grid/onRowChange";
import SelectEditor from "../../../components/Grid/SelectEditor";
import {errorAlert, infoAlert} from "../../../components/Overlay/Alert";
import {confirm, confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Companyprop from "../../../enums/Companyprop";
import CustomerType from "../../../enums/CustomerType";
import Icon from "../../../enums/Icon";
import RentType from "../../../enums/RentType";
import Role from "../../../enums/Role";
import TaxReductionType from "../../../enums/TaxReductionType";
import {getCustomerEmailByIds} from "../../../functions/getCustomerEmailByIds";
import useCompanyProp from "../../../hooks/useCompanyProp";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {findByField, findById, findByMaxId, sum} from "../../../utils/ArrayUtil";
import {todayDate} from "../../../utils/DateUtils";
import {formatCurrency} from "../../../utils/NumberUtils";
import RouterUtils from "../../../utils/RouterUtils";
import useCustomercompanypricelistUtil from "../../Customers/useCustomercompanypricelist";
import FormanswerGrid from "../../Formanswers/FormanswerGrid";
import ProjectService from "../../Projects/ProjectService";
import TenderService from "../TenderService";
import TenderStatus from "../TenderStatus";
import {SendTenderButton} from "./SendTenderButton";
import TenderEmailLogDialog from "./TenderEmailLogDialog";
import TenderhowtoGrid from "./TenderhowtoGrid";
import TendertimeregdebittypepriceGrid from "./TendertimeregdebittypepriceGrid";
import useTenderDefaultValues from "./useTenderDefaultValues";

const TenderEditForm = props => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const pricelistList = preload.getPricelistList()
    const dialog = useDialog()

    const employees = useEmployees()
    const customercompanypricelistUtil = useCustomercompanypricelistUtil()

    const filter = useFilter('TenderEditForm', [
        {name: 'costcenter', defaultValue: undefined},
    ])

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const RENT_DEFAULT_STRATEGY = useCompanyProp(Companyprop.RENT_DEFAULT_STRATEGY, RentType.WEEK.id)
    const USING_PYRAMID = useCompanyProp(Companyprop.PYRAMID, false)

    const [model, setModel] = useState({
        status: TenderStatus.DRAFT,
        rowList: []
    });

    const [common, setCommon] = useState();
    const [numOfForms, setNumOfForms] = useState(0)

    const location = useLocation()
    const navigate = useNavigate()

    const disabled = !model || !userPersmissions.hasRole(Role.TENDER_WRITE);

    const defaultValues = useTenderDefaultValues(common)
    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchOurReference = Number(useWatch({control, name: 'ourReference'}))
    const watchCustomer = Number(useWatch({control, name: 'customer'}))
    const watchCustomercontact = Number(useWatch({control, name: 'customercontact'}))
    const watchStatus = useWatch({control, name: 'status'})
    const watchRowList = useWatch({control, name: 'rowList'})
    const watchOptionList = useWatch({control, name: 'optionList'})
    const watchRentList = useWatch({control, name: 'rentList'})
    const watchHowtoList = useWatch({control, name: 'howtoList'})
    const watchAppendixList = useWatch({control, name: 'appendixList'})
    const watchPricelist = useWatch({control, name: 'pricelist'})
    const pricelist = findById(pricelistList, watchPricelist)

    const save = async (data, files) => {
        if (!disabled) {
            const saveModel = Object.assign({}, model, data);

            if (hasHouseworkRows()) {
                if (!saveModel.taxReductionType) {
                    saveModel.taxReductionType = TaxReductionType.rot.id
                }
            } else {
                delete saveModel.taxReductionType
            }

            return TenderService.update(saveModel, files).then(async _model => {
                if (_model) {

                    filter.update('costcenter', _model.costcenter)

                    setModel(_model)
                    reset(_model)
                    return _model
                }
            });
        } else {
            return model;
        }
    }

    const onSubmit = () => {
        showLoading();

        const data = getValues()
        console.log("Save Model", data)
        return save(data).then(_model => {
            hideLoading();
            if (_model) {
                RouterUtils.goBack(navigate)

                if (!USING_PYRAMID &&
                    userPersmissions.hasRole(Role.PROJECT_WRITE) &&
                    [TenderStatus.DRAFT, TenderStatus.UNANSWERED, TenderStatus.NOT_ACCEPTED].includes(model.status) &&
                    data.status === TenderStatus.ACCEPTED) {
                    confirm({
                        header: i18n('question'),
                        message: i18n('tender_create_project').replace('$0', data.name),
                        options: [{
                            label: i18n('yes'),
                            onClick: () => TenderService.createProject(_model.id).then(_project => {
                                if (_project) infoAlert(i18n('project_created').replace('$0', _project.number + ' - ' + _project.name))
                            })
                        }]
                    })
                }
            }
        })
    }

    const downloadPdf = data => {
        showLoading()
        save(data).then(_model => {
            if (_model) {
                // TenderService.downloadPdf(_model.id).then(() => hideLoading())
                renderOverlay(<PdfViewerModal label={i18n('tender') + ' ' + _model.number}
                                              url={'/api/tenders/' + _model.id + '/pdf'}/>)
                hideLoading();
            } else {
                hideLoading();
            }
        })
    }

    useMountEffect(async () => {
        showLoading();
        const _customerList = preload.get(PreloadType.CUSTOMER_LIST)
        const _costcenterList = preload.get(PreloadType.COSTCENTER_LIST)

        // const [
        //     _kma
        // ] = await Promise.all([
        //     KmaService.get()
        // ])

        setCommon({
            customerList: _customerList,
            articleList: preload.get(PreloadType.ARTICLE_LIST),
            timeregdebittypeList: preload.get(PreloadType.TIMEREGDEBITTYPE_LIST).filter(c => c.active),
            // kma: _kma
        })

        if (location.state) {
            const _model = Object.assign({}, location.state)

            let _customer
            if (!(Number(_model.customer) > 0)) {
                _customer = findByMaxId(_customerList)
                _model.customer = _customer.id
            }
            setModel(_model)
            reset(_model)
            onCustomerChange(_customer)
        } else {
            let id = RouterUtils.getId(location);
            if (id > 0) {
                const [
                    _model,
                    _tenderrowList,
                    _tenderoptionList,
                    _projectrentList
                ] = await Promise.all([
                    TenderService.findById(id),
                    TenderService.findRowsByTender(id),
                    TenderService.findOptionsByTender(id),
                    ProjectService.findRentsByTender(id)
                ])

                if (_model.changeOrder) {
                    errorAlert("Change orders cannot be edited in tender view.")
                    RouterUtils.goBack(navigate)
                }

                // Mark invoiced rows as readonly
                _model.rowList.forEach(tr => {
                    const _tr = _tenderrowList.find(item => item.id === tr.id)
                    tr.readonly = _tr.invoicerowtenderrowList.length > 0
                })
                // Mark invoiced options as readonly
                _model.optionList.forEach(tr => {
                    const _tr = _tenderoptionList.find(item => item.id === tr.id)
                    tr.readonly = _tr.invoicerowtenderoptionList.length > 0
                })

                // Mark invoiced rents as readonly
                _model.rentList.forEach(tr => {
                    const _tr = _projectrentList.find(item => item.tenderrent === tr.id)
                    tr.readonly = _tr !== undefined && _tr.invoicerowprojectrentList.length > 0
                })

                setModel(_model);
                reset(_model);
            } else {
                setValue('ourReference', employees.activeUsable.find(item => item.email === context.user.email)?.id)
                setValue('costcenter', _costcenterList.find(item => item.id === filter.get('costcenter'))?.id)
            }
        }
        hideLoading();
    })

    const copyTenderPdf = data => {
        const sendReq = () => {
            showLoading();
            save(data).then(_model => {
                if (_model) {
                    TenderService.copy(_model.id).then(_copyModel => {
                        if (_copyModel) {
                            navigate('/tenders/' + _copyModel.id, {replace: true});
                            setModel(_copyModel);
                            reset(_copyModel);
                            infoAlert(i18n('copy_is_showing_now'))
                        }
                        hideLoading();
                    });
                } else {
                    hideLoading();
                }
            })
        }
        confirmYes(sendReq)
    }

    const menuItems = [{
        label: i18n('email_log') + '...',
        faicon: Icon.LOG,
        command: () => dialog.show(<TenderEmailLogDialog tender={model}/>),
        hide: !model.id
    }, {
        label: i18n('do_copy') + '...',
        faicon: Icon.COPY,
        command: handleSubmit(copyTenderPdf),
        hide: !model.id
    },
    ].filter(item => !item.hide)


    if (!common) return <Spinner/>

    function isHousework() {
        const customer = ArrayUtil.findById(common.customerList, watchCustomer)
        return customer?.type === CustomerType.PRIVATE_PERSON.id
    }

    function hasHouseworkRows() {
        return !!findByField(watchRowList, 'housework', true)
    }

    function showAnswerDate(_status) {
        return [TenderStatus.ACCEPTED, TenderStatus.NOT_ACCEPTED].indexOf(_status) !== -1
    }

    function onCustomerChange(_customer) {
        if (_customer) {
            setValue('markup', _customer.markup)
            setValue('purchasePercentageMarkup', _customer.purchasePercentageMarkup)
            setValue('timeregdebittypepriceList', _customer.timeregdebittypepriceList.map(item => ({
                    price: item.price,
                    timeregdebittype: item.timeregdebittype,
                })
            ))
            setValue('pricelist', customercompanypricelistUtil.getPricelist(_customer.companypricelistList))
        }
    }

    return (<div className="EditForm">

        <div className="headline">
            <h1>{i18n('tender')} {model.number}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="button-row">
                <div className='left-buttons'>
                    {!disabled && <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                    <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                </div>
                <div className='divider'></div>
                <div className='right-buttons'>
                    <PdfButton labelI18n='preview' onClick={e => handleSubmit(downloadPdf)()}
                               disabled={isSubmitting}/>
                    <SendTenderButton changeOrder={false}
                                      customerId={watchCustomer}
                                      dialog={dialog}
                                      email={getCustomerEmailByIds(common.customerList, watchCustomer, watchCustomercontact)}
                                      tender={getValues()}
                                      ourReference={employees.findById(watchOurReference)}
                                      handleSubmit={handleSubmit} save={save} reset={reset}/>
                    {menuItems.length > 0 && <ExtraMenu itemList={menuItems}/>}
                </div>
            </div>

            <div className='columnContainer'>
                <div className='column'>
                    <FormItemText name='name' labelI18n='name' maxLength={128} required {...form}/>
                    <FormItemDate name='date' labelI18n='date' {...form} />
                    <FormItemAddress name='address' labelI18n='address'
                                     onBlur={(e) => {
                                         const name = getValues('name')
                                         if (name.length === 0) {
                                             const address = getValues('address')
                                             setValue('name', address.address)
                                         }
                                     }}
                                     {...form}/>
                    <FormItemTextArea name='message' labelI18n='message_to_customer' maxLength={512} {...form}/>
                </div>
                <div className='column'>
                    <FormItemCustomerAndContact
                        form={form}
                        onCustomerEdit={() => {
                            const data = getValues()
                            navigate(location.pathname, {replace: true, state: data});
                            navigate('/customers/' + (data.customer ? data.customer : 0));
                        }}
                        onCustomerChange={onCustomerChange}
                    />
                    <FormItemTextArea name='markup' labelI18n='reference_no' maxLength={250} {...form}/>
                </div>
                <div className='column'>
                    <FormItemEmployee name='ourReference' labelI18n='our_reference' required {...form}/>
                    <FormItemCostcenter {...form} />
                    <FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>
                    <FormItemDropdown name='status' labelI18n='status'
                                      options={TenderStatus.LIST.map(c => ({id: c.value, name: c.label}))}
                                      width={160}
                                      onChange={e => {
                                          const _status = e.target.value
                                          const _answerDate = getValues('answerDate')
                                          if (!_answerDate && showAnswerDate(_status)) {
                                              setValue('answerDate', todayDate())
                                          }
                                      }}
                                      required {...form} />
                    {
                        showAnswerDate(watchStatus) &&
                        <FormItemOptionalDate name='answerDate' labelI18n='answer_date' {...form} />
                    }
                </div>
            </div>

            <TabView renderActiveOnly={false}>
                <TabPanel key='rows' header={i18n('rows') + ' (' + (watchRowList.length) + ')'}>
                    <FormItemRowGrid name='rowList'
                                     articleList={common.articleList}
                                     pricelist={pricelist}
                                     isCellEditable={(field, rowData) => !rowData.readonly}
                                     defaultRowValue={{
                                         id: 0,
                                         article: undefined,
                                         onlyTotal: true,
                                         name: '',
                                         quantity: 0,
                                         aprice: 0,
                                         discount: 0,
                                         price: 0
                                     }}
                                     housework={isHousework()}
                                     {...form}/>
                </TabPanel>
                <TabPanel key='options' header={i18n('options') + ' (' + (watchOptionList.length) + ')'}>
                    <FormItemRowGrid name='optionList'
                                     articleList={common.articleList}
                                     pricelist={pricelist}
                                     isCellEditable={(field, rowData) => !rowData.readonly}
                                     defaultRowValue={{
                                         id: 0,
                                         article: undefined,
                                         onlyTotal: true,
                                         name: '',
                                         quantity: 0,
                                         aprice: 0,
                                         discount: 0,
                                         price: 0
                                     }}
                                     housework={isHousework()}
                                     {...form}/>
                </TabPanel>
                <TabPanel key='rents' header={i18n('rents') + ' (' + (watchRentList.length) + ')'}>
                    <FormItemRowGrid name='rentList'
                                     articleList={common.articleList}
                                     pricelist={pricelist}
                                     isCellEditable={(field, rowData) => !rowData.readonly}
                                     defaultRowValue={{
                                         id: 0,
                                         article: undefined,
                                         onlyTotal: true,
                                         name: '',
                                         type: RENT_DEFAULT_STRATEGY,
                                         quantity: 0,
                                         aprice: 0,
                                         discount: 0,
                                         price: 0
                                     }}
                                     getCustomColumns={(rowList, fireChange) => {
                                         return [{
                                             field: 'type', header: i18n('type'),
                                             body: row => !isFreeRow(rowList, row)
                                                 ? RentType.getName(row.type)
                                                 : '',
                                             editor: e => e.rowData.readonly ? undefined :
                                                 <SelectEditor event={e}
                                                               options={RentType.values()}
                                                               optionValue='id'
                                                               hideNoneOption={true}
                                                               onChange={e2 => e.editorCallback(e2?.value)}/>,
                                             onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
                                             afterField: 'name',
                                             width: 80,
                                         }]
                                     }}
                                     footer={<RentFooter form={form}/>}
                                     {...form}/>
                </TabPanel>
                <TabPanel key='howtos' header={i18n('howtos') + ' (' + (watchHowtoList.length) + ')'}>
                    <div className="formItem">
                        <label htmlFor='howtoList'>{i18n('howtos')}</label>
                        <Controller name='howtoList'
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <TenderhowtoGrid inputRef={ref}
                                                         value={value}
                                                         onChange={onChange}/>}/>
                    </div>
                </TabPanel>
                <TabPanel key='standard_values' header={i18n('standard_values')}>
                    <div className='columnContainer'>
                        <div className='column'>

                            {
                                pricelistList.length > 0 &&
                                <FormItemDropdown name='pricelist' labelI18n='pricelist'
                                                  options={pricelistList.filter(item => item.id === model?.pricelist || item.active)}
                                                  showSelect
                                                  {...form} />
                            }

                            {
                                !watchPricelist && !HIDE_ARTICLE_PRICES &&
                                <div className="formItem">
                                    <label>{i18n('costplus_time')}</label>
                                    <Controller name='timeregdebittypepriceList'
                                                control={control}
                                                render={({field: {ref, value, onChange}}) =>
                                                    <TendertimeregdebittypepriceGrid
                                                        inputRef={ref} value={value}
                                                        onChange={onChange}
                                                        timeregdebittypeList={common.timeregdebittypeList}
                                                    />}/>
                                </div>
                            }


                        </div>
                        <div className='column'>
                            <FormItemInteger name='validityTime' labelI18n='validity_time' required min={0}
                                             max={100} {...form}/>
                            <FormItemNumber name='purchasePercentageMarkup'
                                            labelI18n='purchase_percentage_markup' required {...form}/>
                            <FormItemNumber name='expectedMargin' labelI18n='expected_margin_percentage'
                                            required {...form}/>
                        </div>
                        <div className='column'>
                            {
                                isHousework() &&
                                <div style={{width: '100px'}}>
                                    <FormItemDropdown name='taxReductionType' labelI18n='house_work'
                                                      options={TaxReductionType.values()}
                                                      showSelect showSelectValue={null}
                                                      {...form} />
                                </div>
                            }
                        </div>
                    </div>
                </TabPanel>

                <TabPanel key='forms' header={i18n('forms') + ' (' + (numOfForms) + ')'}>
                    <FormanswerGrid tenderId={model?.id || 0} save={save} form={form}
                                    onLoad={e => {
                                        setNumOfForms(e.count)
                                    }}
                    />
                </TabPanel>
                <TabPanel key='appendices' header={i18n('appendices') + ' (' + (watchAppendixList.length) + ')'}>
                    <FormItemAppendixGrid name='appendixList' save={save} {...form}/>
                </TabPanel>
            </TabView>
        </form>
        {dialog.render()}
    </div>);
}

export default TenderEditForm;


export function RentFooter({name = 'rentList', form}) {
    const rentList = form.getValues(name)
    const typeRentListDictionary = groupBy(rentList, item => item.type)

    return <div className='footer'>
        <div className='left'></div>
        <div className='middle'></div>
        <div className='right'>
            <table>
                <tbody>
                {
                    RentType.values()
                        .filter(rentType => typeRentListDictionary[rentType.id] !== undefined)
                        .map(rentType => {
                            const totalPrice = sum(typeRentListDictionary[rentType.id], 'price')
                            return <tr key={rentType.id} className='to-pay'>
                                <td>{rentType.name}:</td>
                                <td>{formatCurrency(totalPrice, 2)}</td>
                            </tr>
                        })
                }
                </tbody>
            </table>
        </div>
    </div>
}