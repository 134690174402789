import {useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";

const FetchChangeordersDialog = props => {
    const filter = useFilter('FetchChangeordersDialog', [
        {name: 'separateDates', defaultValue: true},
        {name: 'separateEmployees', defaultValue: false},
        {name: 'includeEmployeeComments', defaultValue: false},
    ])

    const {
        changeorderList,
        onSave
    } = props
    const [selection, setSelection] = useState([])

    const defaultValues = {
        separateDates: filter.separateDates,
        separateEmployees: filter.separateEmployees,
        includeEmployeeComments: filter.includeEmployeeComments,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues, control} = form

    const watchSeparateDates = useWatch({control, name: 'separateDates'})

    const onSubmit = () => {
        const data = getValues();
        filter.update('separateDates', data.separateDates)
        filter.update('separateEmployees', data.separateEmployees)
        filter.update('includeEmployeeComments', data.includeEmployeeComments)
        onSave(selection, {
            separateDates: data.separateDates,
            separateEmployees: data.separateEmployees,
            includeEmployeeComments: data.includeEmployeeComments
        })
    }

    const columns = [
        {field: 'label', headerI18n: 'name'},
        {
            field: 'totalUninvoiced', headerI18n: 'uninvoiced',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 100
        },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    function getLeftButtons() {
        const result = [
            <FormItemCheckbox key='separateDates' name='separateDates'
                              labelI18n='separate_dates' {...form}/>,
            <FormItemCheckbox key='separateEmployees' name='separateEmployees'
                              labelI18n='separate_employees' {...form}/>
        ]
        if (watchSeparateDates) {
            result.push(
                <FormItemCheckbox key='includeEmployeeComments' name='includeEmployeeComments'
                                  labelI18n='include_employee_comments' {...form}/>
            )
        }
        return result
    }


    return <XlnzDialog header={i18n('change_orders')}
                       onHide={props.onHide}
                       leftButtons={getLeftButtons}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '800px'}}>
            <Grid name={i18n('change_orders')}
                  forceDesktop={true}
                  columns={columns}
                  value={changeorderList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>

    </XlnzDialog>
}
export default FetchChangeordersDialog