const usedColors = new Set();

export const getRandomColor = () => {
    let hue;
    let color;
    do {
        hue = Math.floor(Math.random() * 360);
        color = `hsl(${hue}, 70%, 60%)`;
    } while (usedColors.has(color));

    usedColors.add(color);
    return color;
};

export const getDaysArray = (start, end) => {
    const arr = [];
    const dt = new Date(start);
    const endDate = new Date(end);

    while (dt <= endDate) {
        arr.push(new Date(dt).toISOString().split('T')[0]);
        dt.setDate(dt.getDate() + 1);
    }

    return arr;
};

export const moveProject = (projects, draggedProject, newEmployeeId, newStartDate) => {
    return projects.map(project => {
        if (project.id === draggedProject.id) {
            const duration = (new Date(project.endDate) - new Date(project.startDate)) / (1000 * 60 * 60 * 24);
            const newEndDate = new Date(newStartDate);
            newEndDate.setDate(newEndDate.getDate() + duration);

            return {
                ...project,
                employee: newEmployeeId,
                startDate: newStartDate,
                endDate: newEndDate.toISOString().split('T')[0]
            };
        }
        return project;
    });
};

export const resizeProject = (projects, projectId, newStartDate, newEndDate) => {
    return projects.map(project => {
        if (project.id === projectId) {
            return {
                ...project,
                startDate: newStartDate,
                endDate: newEndDate
            };
        }
        return project;
    });
};

