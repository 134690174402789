import '@components/EditForm/EditForm.scss'
import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAddress from "../../../components/EditForm/FormItemAddress";
import FormItemAppendixGrid from "../../../components/EditForm/FormItemAppendixGrid";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import FormItemInteger from "../../../components/EditForm/FormItemInteger";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemPhone from "../../../components/EditForm/FormItemPhone";
import FormItemRegNo from "../../../components/EditForm/FormItemRegNo";
import FormItemSsn from "../../../components/EditForm/FormItemSsn";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import FormItemWww from "../../../components/EditForm/FormItemWww";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Companyprop from "../../../enums/Companyprop";
import Country from "../../../enums/Country";
import CustomerType from "../../../enums/CustomerType";
import InvoiceSendMethod from "../../../enums/InvoiceSendMethod";
import Language from "../../../enums/Language";
import Role from "../../../enums/Role";
import VatType from "../../../enums/VatType";
import useCompanyProp from "../../../hooks/useCompanyProp";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import RouterUtils from "../../../utils/RouterUtils";
import CustomerService from "../CustomerService";
import useCustomercompanypricelistUtil from "../useCustomercompanypricelist";
import CustomercontactGrid from "./CustomercontactGrid";
import CustomertimeregdebittypepriceGrid from "./CustomertimeregdebittypepriceGrid";

const CustomerEditForm = () => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const pricelistList = preload.getPricelistList()

    const forceUpdate = useForceUpdate();

    const [model, setModel] = useState()

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const CUSTOMER_PAYMENT_TERMS_COMPANY = useCompanyProp(Companyprop.CUSTOMER_PAYMENT_TERMS_COMPANY, 30)
    const CUSTOMER_PAYMENT_TERMS_PRIVATE = useCompanyProp(Companyprop.CUSTOMER_PAYMENT_TERMS_PRIVATE, 30)
    const isUsingFactoring = useCompanyProp(Companyprop.INVOICE_FACTORING_TEXT)?.length > 0

    const location = useLocation()
    const navigate = useNavigate()

    const customercompanypricelistUtil = useCustomercompanypricelistUtil()

    const disabled = !model || !userPersmissions.hasRole(Role.CUSTOMER_WRITE)

    const defaultValues = {
        type: CustomerType.COMPANY.id,
        name: '',
        number: '',
        vatNo: '',
        markup: '',
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        visitingAddress: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        language: Language.SWEDISH.id,
        vatType: VatType.SEVAT.id,
        paymentTerms: CUSTOMER_PAYMENT_TERMS_COMPANY,
        invoiceSendMethod: InvoiceSendMethod.EMAIL.id,
        purchasePercentageMarkup: 0,
        note: '',
        active: true,
        contactList: [],
        timeregdebittypepriceList: [],
        companypricelistList: [],
        appendixList: [],
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control, unregister} = form

    const watchType = useWatch({control, name: 'type'})
    const isPrivatePerson = watchType === CustomerType.PRIVATE_PERSON.id
    const watchAddressCountry = useWatch({control, name: 'address.country'})

    const watchPricelist = useWatch({control, name: 'pricelist'})
    const watchInvoiceSendMethod = useWatch({control, name: 'invoiceSendMethod'})
    const watchAppendixList = useWatch({control, name: 'appendixList'})

    const validate = async data => {
        if (data.regNo.length > 0) {
            const existResp = await CustomerService.exists(data.id || 0, data.regNo)
            if (existResp.exists) {
                errorAlert(i18n('customer_already_exists') + ': ' + existResp.customerNumberList.join(', '))
                return false
            }
        }
        return true
    }

    const save = async (data, files) => {
        if (!await validate(data)) {
            hideLoading()
            return undefined
        }

        if (!disabled) {
            // data.company = context.currentCompany.id
            data.company = context.currentCompany.parentId || context.currentCompany.id
            if (data.vatNo === '') delete data.vatNo

            console.log("SAVE MODEL:", data)
            const _model = await CustomerService.update(data, files)

            if (_model) {
                setModel(_model)
                reset(_model)
            }
            await preload.load(PreloadType.CUSTOMER_LIST)
            return _model
        } else {
            return model;
        }
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const id = RouterUtils.getId(location)
        if (id > 0) {
            const _model = await CustomerService.findById(id)

            _model.pricelist = customercompanypricelistUtil.getPricelist(_model.companypricelistList)

            setModel(_model)
            reset(_model)
        } else {
            setModel(defaultValues)
        }
        hideLoading()
    })

    if (!model) return <Spinner/>
    return (
        <div className='EditForm'>
            <div className="headline">
                <h1>{i18n('customer')} {model?.number}</h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled &&
                            <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                </div>
                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemDropdown key='type' name='type' labelI18n='type' options={CustomerType.values()}
                                          onChange={e => {
                                              const _type = e.target.value
                                              console.log("_type", _type)
                                              switch (_type) {
                                                  case CustomerType.COMPANY.id:
                                                      setValue('paymentTerms', CUSTOMER_PAYMENT_TERMS_COMPANY)
                                                      break
                                                  case CustomerType.PRIVATE_PERSON.id:
                                                      setValue('paymentTerms', CUSTOMER_PAYMENT_TERMS_PRIVATE)
                                                      break
                                                  default:
                                                      break
                                              }

                                              const _regNo = getValues('regNo')
                                              unregister('regNo')
                                              setValue('regNo', _regNo)
                                              forceUpdate()
                                          }}
                                          required {...form} />
                        {isPrivatePerson && <FormItemSsn key='regNo' name='regNo' {...form}/>}
                        {!isPrivatePerson && <FormItemRegNo country={watchAddressCountry} form={form}/>}
                        {/*// <FormItemText key='regNo' name='regNo' labelI18n='reg_no' maxLength={32} width={150}*/}
                        {/*//               onBlur={e => findCompanyInfoByRegNo(e.target.value)} {...form}/>*/}


                        <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>
                        <FormItemText key='vatNo' name='vatNo' labelI18n='vat_no' maxLength={45} width={130}
                                      hidden={isPrivatePerson}
                                      {...form}/>
                        <FormItemTextArea key='markup' name='markup' labelI18n='reference_no'
                                          maxLength={250} {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemAddress key='address' name='address' labelI18n='address' {...form}/>
                        <FormItemAddress key='visitingAddress' name='visitingAddress'
                                         labelI18n='visiting_address' {...form}/>
                        <FormItemPhone key='phone0' name='phone0' labelI18n='mobile' {...form}/>
                        <FormItemEmail key='email' name='email' labelI18n='email' {...form}/>
                        <FormItemWww key='www' name='www' labelI18n='homepage' {...form}/>
                        <FormItemDropdown key='language' name='language' labelI18n='language'
                                          options={Language.values()}
                                          required {...form} />
                    </div>
                    <div className='column'>
                        <FormItemDropdown key='vatType' name='vatType' labelI18n='vat_type' options={VatType.values()}
                                          required {...form} />
                        <FormItemDropdown key='invoiceSendMethod' name='invoiceSendMethod' labelI18n='invoice_method'
                                          options={InvoiceSendMethod.values()}
                                          required {...form} />
                        <FormItemEmail key='invoiceEmail' name='invoiceEmail' labelI18n='email_for_invoices'
                                       hidden={watchInvoiceSendMethod !== InvoiceSendMethod.EMAIL.id}
                                       {...form}/>
                        <FormItemInteger key='paymentTerms' name='paymentTerms' labelI18n='payment_terms' {...form}/>
                        {
                            isUsingFactoring &&
                            <FormItemCheckbox key='disableFactoring' name='disableFactoring'
                                              labelI18n='disable_factoring' {...form}/>
                        }
                        <FormItemInteger key='credit' name='credit' labelI18n='creditworthiness' {...form}/>
                        <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>
                        <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
                    </div>
                </div>
                <TabView>
                    <TabPanel key='contact_persons' header={i18n('contact_persons')}>
                        <Controller name="contactList"
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <CustomercontactGrid
                                            inputRef={ref} value={value} onChange={onChange}
                                        />
                                    }/>
                    </TabPanel>
                    <TabPanel key='standard_values' header={i18n('standard_values')}>
                        <div className='columnContainer'>
                            <div className='column'>

                                {
                                    pricelistList?.length > 0 &&
                                    <FormItemDropdown
                                        name='pricelist' labelI18n='pricelist'
                                        options={pricelistList.filter(item => item.id === model?.pricelist || item.active)}
                                        showSelect
                                        onChange={e => {
                                            let _companypricelistList = [...getValues('companypricelistList')]

                                            const _pricelist = e.target.value
                                            if (_pricelist > 0) {
                                                let _oldValue = customercompanypricelistUtil.getCustomercompanypricelist(_companypricelistList)

                                                if (_oldValue) {
                                                    _oldValue.pricelist = _pricelist
                                                } else {
                                                    _companypricelistList.push({
                                                        customer: model.id,
                                                        company: context.currentCompany.id,
                                                        pricelist: _pricelist
                                                    })
                                                }
                                            } else {
                                                _companypricelistList = _companypricelistList.filter(item => item.company !== context.currentCompany.id)
                                            }

                                            setValue('companypricelistList', _companypricelistList)
                                            forceUpdate()
                                        }}
                                        {...form} />
                                }

                                {
                                    !watchPricelist && !HIDE_ARTICLE_PRICES &&
                                    <div className="formItem">
                                        <label>{i18n('costplus_time')}</label>
                                        <Controller name='timeregdebittypepriceList'
                                                    control={control}
                                                    render={({field: {ref, value, onChange}}) =>
                                                        <CustomertimeregdebittypepriceGrid
                                                            inputRef={ref} value={value} disabled={disabled}
                                                            onChange={onChange}
                                                        />}/>
                                    </div>
                                }
                            </div>
                            <div className='column'>
                                <FormItemNumber name='purchasePercentageMarkup'
                                                labelI18n='purchase_percentage_markup'
                                                {...form}/>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel key='appendices' header={i18n('appendices') + ' (' + (watchAppendixList.length) + ')'}>
                        <FormItemAppendixGrid name='appendixList' save={save} hideAttach={true} {...form}/>
                    </TabPanel>
                </TabView>
            </form>
        </div>
    )
}

export default CustomerEditForm;
