import {faPlus} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React, {useCallback, useMemo, useState} from 'react'
import {findById, sortByField} from "../../../utils/ArrayUtil"
import {addDays, getDaysBetween} from "../../../utils/DateUtils";
import ProjectBlock from './ProjectBlock'
import {getDaysArray, getRandomColor} from './schedulerUtils'
import './Scheduler.scss'

const Scheduler = props => {
    const {
        startDate,
        employeeList,
        planningList,
        onPlanningAdd,
        onPlanningChange
    } = props

    const currentDate = startDate
    const [viewMode, setViewMode] = useState('week')

    const days = useMemo(() => {
        const startDate = new Date(currentDate)
        startDate.setDate(startDate.getDate() - startDate.getDay() + 1) // Start from Monday
        let endDate
        if (viewMode === 'week') {
            endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + 6)
        } else if (viewMode === '2weeks') {
            endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + 13)
        } else if (viewMode === 'month') {
            endDate = new Date(startDate)
            endDate.setMonth(endDate.getMonth() + 1)
            endDate.setDate(0) // Last day of the month
        }
        return getDaysArray(startDate, endDate)
    }, [currentDate, viewMode])

    const handleDragOver = useCallback((e) => {
        e.preventDefault()
        e.currentTarget.classList.add('scheduler__drag-over')
    }, [])

    const handleDragLeave = useCallback((e) => {
        e.preventDefault()
        e.currentTarget.classList.remove('scheduler__drag-over')
    }, [])

    const handleDrop = useCallback((e, employeeId, date) => {
        e.preventDefault()
        e.currentTarget.classList.remove('scheduler__drag-over')

        const droppedPlanning = JSON.parse(e.dataTransfer.getData('text/plain'))
        if (!droppedPlanning) return

        // Don't update if dropping on the same day and employee
        if (droppedPlanning.startDate === date && droppedPlanning.employee === employeeId) return

        const duration = getDaysBetween(droppedPlanning.startDate, droppedPlanning.endDate)
        const updatedPlanning = {
            ...droppedPlanning,
            employee: employeeId,
            startDate: date,
            endDate: addDays(date, duration - 1)
        }
        onPlanningChange(updatedPlanning)
    }, [planningList])

    const handleResize = useCallback((planningId, newStartDate, newEndDate, isResizing) => {
        const planning = findById(planningList, planningId)
        const updatedPlanning = {
            ...planning,
            startDate: newStartDate,
            endDate: newEndDate
        }
        onPlanningChange(updatedPlanning, isResizing)
    }, [planningList])

    const handleCellClick = (employeeId, date) => {
        // const newPlanning = {
        //     id: Date.now(),
        //     employee: employeeId,
        //     name: 'New Project',
        //     startDate: date,
        //     endDate: date,
        //     color: getRandomColor()
        // }
        onPlanningAdd(employeeId, date)
    }

    function getNumActiveProjectDuringWeek(projects, employeeId) {
        let weekStartDate = days[0]
        let weekEndDate = days[days.length - 1]

        const weekStart = new Date(weekStartDate)
        const weekEnd = new Date(weekEndDate)

        return projects.filter(project => {
            // Kontrollera om det är rätt anställd
            if (project.employee !== employeeId) return false

            // Konvertera projektets start- och slutdatum
            const projectStart = new Date(project.startDate)
            const projectEnd = new Date(project.endDate)

            // Kontrollera om projektet överlappar med veckan
            return (
                (projectStart <= weekEnd && projectEnd >= weekStart) // Överlappning
            )
        }).length
    }

    function getProjectRowIndex(projects, employeeId, projectId) {
        const sortedProjects = sortByField(projects, 'startDate')


        const filteredProjects = sortedProjects.filter(project => project.employee === employeeId)
        return filteredProjects.findIndex(project => project.id === projectId)
    }

    function generateColorFromId(id) {
        // Enkel hashfunktion
        const hash = id * 2654435761 % Math.pow(2, 32);
        // Konvertera hash till en HEX-färg
        return `#${((hash & 0xFFFFFF).toString(16)).padStart(6, '0')}`;
    }

    const renderProjectBlocks = (employeeId, day) => {
        const projectsForDay = planningList.filter(project =>
            project.employee === employeeId &&
            project.startDate <= day &&
            project.endDate >= day
        )

        return projectsForDay.map((planning, index) => {
            const isFirstDay = planning.startDate === day
            if (isFirstDay) {
                const projectDuration = Math.min(
                    (new Date(planning.endDate) - new Date(planning.startDate)) / (1000 * 60 * 60 * 24) + 1,
                    days.length - days.indexOf(day)
                )

                return <ProjectBlock
                    key={planning.id}
                    planning={planning}
                    onResize={handleResize}
                    startDate={planning.startDate}
                    endDate={planning.endDate}
                    width={`${projectDuration * 100}%`}
                    color={generateColorFromId(planning.id)}
                    // color={planning.color}
                    top={`${getProjectRowIndex(planningList, employeeId, planning.id) * 30}px`}
                />
            }
            return null
        })
    }

    return (
        <div className="scheduler">
            {/*<div className="scheduler__controls">*/}
            {/*    <div className="scheduler__view-modes">*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === 'week' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('week')}*/}
            {/*        >*/}
            {/*            Vecka*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === '2weeks' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('2weeks')}*/}
            {/*        >*/}
            {/*            2 veckor*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === 'month' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('month')}*/}
            {/*        >*/}
            {/*            Månad*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="scheduler__grid">
                <div className="scheduler__header">
                    <div className="scheduler__employee-column"></div>
                    {days.map(day => (
                        <div key={day} className="scheduler__day-column">
                            <div className="day-name">
                                {new Date(day).toLocaleDateString('sv-SE', {weekday: 'short'})}
                            </div>
                            <div className="day-number">
                                {new Date(day).getDate()}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="scheduler__body">
                    {employeeList.map(employee => (
                        <div key={employee.id} className="scheduler__row">
                            <div className="scheduler__employee-name">{employee.name}</div>
                            {
                                days.map(day => {
                                    const numOfActiveProjects = getNumActiveProjectDuringWeek(planningList, employee.id)
                                    const cellHeight = numOfActiveProjects * 30 + 30

                                    return (
                                        <div
                                            key={`${employee.id}-${day}`}
                                            className="scheduler__cell"
                                            style={{height: cellHeight + 'px'}}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave}
                                            onDrop={(e) => handleDrop(e, employee.id, day)}
                                        >
                                            {renderProjectBlocks(employee.id, day)}

                                            {
                                                // !isProjectResizing &&
                                                <div className='new-project-button-container'>
                                                    <div className='new-project-button'
                                                         onClick={() => handleCellClick(employee.id, day)}>
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Scheduler

