import React from 'react';
import Grid from "../../components/Grid/Grid";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";

const FormStats = props => {
    const {
        value
    } = props

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'companyName', headerI18n: 'company'},
        {field: 'num', headerI18n: 'quantity', body: GridTemplates.integer, align: 'right', width: 100},
    ]

    return <XlnzCard title='Form Stats' style={{width: '800px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={row => {
            }}
            forceDesktop={true}
        />
    </XlnzCard>

}
export default FormStats;