import NetUtils from "../../utils/NetUtils";

const CustomerService = {
    findAll: () => NetUtils.doGet('/api/customers'),

    findByActive: async () => NetUtils.doGet('/api/customers').then(customerList => customerList.filter(c => c.active)),

    findById: id => NetUtils.doGet('/api/customers/' + id),
    findByUser: userId => NetUtils.doGet('/api/customers?user=' + userId),
    findDivisions: id => NetUtils.doGet('/api/customers/' + id + '/divisions'),
    update: (model, files) => NetUtils.doPostWithFiles('/api/customers', model, files),

    exists: (id, regNo) => NetUtils.doGet('/api/customers/exists/' + id + '/' + regNo),
    downloadEmailsExcel: idList => NetUtils.downloadFile('/api/customers/emails/excel', {idList: idList}),
    getRented: (customerId, date) => NetUtils.doGet('/api/customers/' + customerId + '/rented', {date: date}),

    contactInUse: (customerId, contactId) => NetUtils.doGet('/api/customers/' + customerId + '/contact-in-use/' + contactId),
}
export default CustomerService;