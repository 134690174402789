import React, {useEffect, useRef} from 'react';

const ProjectBlock = props => {
    const {
        planning,
        onResize,
        startDate,
        endDate,
        width,
        color,
        top
    } = props;

    const blockRef = useRef(null);
    const startResizeRef = useRef(null);
    const endResizeRef = useRef(null);

    useEffect(() => {
        const block = blockRef.current;
        if (!block) return;

        const handleDragStart = (e) => {
            e.dataTransfer.setData('text/plain', JSON.stringify(planning));
            setTimeout(() => block.style.display = 'none', 0);
        };

        const handleDragEnd = () => {
            block.style.display = 'block';
        };

        block.addEventListener('dragstart', handleDragStart);
        block.addEventListener('dragend', handleDragEnd);

        return () => {
            block.removeEventListener('dragstart', handleDragStart);
            block.removeEventListener('dragend', handleDragEnd);
        };
    }, [planning]);

    const handleResizeStart = (e, edge) => {
        // e.stopPropagation();
        const initialX = e.clientX;
        const cellWidth = document.querySelector('.scheduler__cell').offsetWidth;

        const handleMouseMove = (moveEvent) => {
            const deltaX = moveEvent.clientX - initialX;
            const daysChange = Math.round(deltaX / cellWidth);

            if (edge === 'start') {
                const newStartDate = new Date(startDate);
                newStartDate.setDate(newStartDate.getDate() + daysChange);
                if (newStartDate <= new Date(endDate)) {
                    onResize(planning.id, newStartDate.toISOString().split('T')[0], endDate, true);
                }
            } else {
                const newEndDate = new Date(endDate);
                newEndDate.setDate(newEndDate.getDate() + daysChange);
                if (newEndDate >= new Date(startDate)) {
                    onResize(planning.id, startDate, newEndDate.toISOString().split('T')[0], true);
                }
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            onResize(planning.id, planning.startDate, planning.endDate, false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div
            ref={blockRef}
            className="project-block"
            style={{
                width,
                backgroundColor: color,
                top,
                zIndex: parseInt(top) + 1
            }}
            draggable="true"
        >
            <div
                ref={startResizeRef}
                className="project-block__resize project-block__resize--start"
                onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleResizeStart(e, 'start')
                }}
            />
            <div className="project-block__content">{planning.name}</div>
            <div
                ref={endResizeRef}
                className="project-block__resize project-block__resize--end"
                // onMouseDown={(e) => handleResizeStart(e, 'end')}
                onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleResizeStart(e, 'end')
                }}
            />
        </div>
    );
};

export default ProjectBlock;

