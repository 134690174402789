import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import Tooltip from "../Overlay/Tooltip";

const ProgressIcon = ({progress, forceDone = false, tooltip}) => {
    if (progress < 0) return undefined


    const size = 20
    const strokeWidth = 4
    const radius = (size - strokeWidth) / 2
    const circumference = 2 * Math.PI * radius
    const dashOffset = circumference * (1 - progress)

    return <Tooltip value={tooltip} key={tooltip}>


        {
            forceDone &&
            <FontAwesomeIcon fontSize={size} icon={faCheckCircle} color="green"/>
        }


        {
            !forceDone &&
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {/* Background circle */}
                <circle cx={size / 2} cy={size / 2} r={radius} fill="none" stroke="#E0E0E0" strokeWidth={strokeWidth}/>
                {/* Progress circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="none"
                    stroke="#4CAF50"
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    strokeLinecap="round"
                />
            </svg>
        }

    </Tooltip>
};

export default ProgressIcon;

