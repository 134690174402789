import {faCalendarWeek} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useEffect, useState} from 'react';
import {DebounceInput} from "react-debounce-input";
import {useForm} from "react-hook-form";
import AppContext from "../../../AppContext";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import DateStepper from "../../../components/Date/DateStepper";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemProject from "../../../components/EditForm/FormItemProject";
import FormItemText from "../../../components/EditForm/FormItemText";
import Label from "../../../components/EditForm/Label";
import {useFilter} from "../../../components/Grid/filter";
import GridLabel from "../../../components/Grid/GridLabel";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import {addDays, getFirstDateOfWeek, isWithinInterval, now} from "../../../utils/DateUtils";
import ProjectService from "../../Projects/ProjectService";
import ProjectStatus from "../../Projects/ProjectStatus";
import DaylockService from "../DaylockService";
import Scheduler from "./Scheduler";
import TimeregplanningService from "./TimeregplanningService";

export const WEEKPLANNING_NUM_OF_DAYS = 7

const ResourcePlanningView = () => {
    const roles = useRoles()
    const filter = useFilter('ResourcePlanningView', [
        {name: 'date', defaultValue: getFirstDateOfWeek()},
        {name: 'costcenter', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [timeregplanningList, setTimeregplanningList] = useState()
    const [daylockList, setDaylockList] = useState()
    const [projectList, setProjectList] = useState()
    const disabled = !roles.hasRole(Role.TIMEREG_WRITE);

    const preload = usePreload()
    const costcenterList = preload.getCostcenterList().filter(item => item.active)

    const dialog = useDialog()

    const employees = useEmployees()

    const loadData = () => {
        async function f() {
            showLoading()

            const startDate = filter.date
            const endDate = addDays(startDate, WEEKPLANNING_NUM_OF_DAYS - 1)

            const [
                _timeregplanningList,
                _daylockList,
            ] = await Promise.all([
                TimeregplanningService.findByDateInterval(startDate, endDate),
                DaylockService.findByDateInterval(startDate, endDate),
            ])

            const forceProjectIdList = _timeregplanningList.map(item => item.project)

            const _projectList = await ProjectService.findAll({
                status: ProjectStatus.ONGOING.id,
                startDate: startDate,
                endDate: endDate,
                forceIds: forceProjectIdList
            })

            for (const timeregplanning of _timeregplanningList) {
                const project = findById(_projectList, timeregplanning.project)

                timeregplanning.name = project.label
            }

            setTimeregplanningList(_timeregplanningList)
            setDaylockList(_daylockList)
            setProjectList(_projectList)
            hideLoading()
        }

        f()
    }
    useEffect(loadData, [filter.date]);

    const isProjectInactive = (mDate, item) => !isWithinInterval(mDate, item.startDate, item.endDate)

    function isDayLocked(date) {
        return daylockList?.find(dl => dl.date === date) !== undefined
    }

    const leftFilters = [
        <div>
            <label className="label">{i18n('week')}</label>
            <DateStepper value={filter.date} weekPicker={true} onChange={date => {
                filter.update('date', date)
            }}/>
        </div>
    ]

    function onPlanningAdd(employeeId, date) {
        // setTimeregplanningList([...timeregplanningList, newPlanning])
        dialog.show(<AddPlanning projectList={projectList}
                                 employee={employeeId}
                                 date={date}
                                 onSave={loadData}
        />)
    }

    async function onPlanningChange(updatedPlanning, moving) {
        const trp = findById(timeregplanningList, updatedPlanning.id)
        if (
            trp.employee != updatedPlanning.employee ||
            trp.startDate != updatedPlanning.startDate ||
            trp.endDate != updatedPlanning.endDate ||
            !moving
        ) {
            if (moving) {
                Object.assign(trp, updatedPlanning)
                setTimeregplanningList([...timeregplanningList])
            } else {
                showLoading()
                const savedPlanning = await TimeregplanningService.update(updatedPlanning)
                Object.assign(trp, updatedPlanning)
                setTimeregplanningList([...timeregplanningList])
                hideLoading()
            }
        }
        // TimeregplanningService.update(updatedPlanning)
    }

    if (!timeregplanningList) return <Spinner/>
    return <div className='Weekplanning2View Grid'>
        <div className='grid-label'>
            <GridLabel icon={faCalendarWeek} labelI18n='resource_planning'/>
        </div>
        <div id='filter' className="filter">
            {leftFilters.map((f, index) => <div key={index}>{f}</div>)}
            <div style={{flexGrow: 1, padding: 0}}></div>
            <div>
                <Label valueI18n='search'/>
                <DebounceInput
                    name='search'
                    value={filter.search}
                    className="search"
                    // minLength={2}
                    debounceTimeout={300}
                    onChange={(e) => {
                        filter.update('search', e.target.value)
                    }}/>
            </div>
        </div>
        <Scheduler startDate={filter.date}
                   employeeList={employees.activeUsable}
                   planningList={timeregplanningList}
                   onPlanningAdd={onPlanningAdd}
                   onPlanningChange={onPlanningChange}
        />
        {dialog.render()}
    </div>
}

export default ResourcePlanningView;


function AddPlanning(props) {
    const {
        onHide,
        onSave,
        model,
        projectList,
        employee,
        date
    } = props
    const context = useContext(AppContext)
    const roles = useRoles()
    const disabled = !roles.hasRole(Role.TIMEREG_WRITE)

    const defaultValues = {
        company: context.currentCompany.id,
        employee: employee,
        ctime: now(),
        startDate: date,
        endDate: date,
    }
    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, formState: {isSubmitting}} = form

    const onSubmit = async data => {
        console.log("SAVE TIMEREGPLANNING", data)
        const savedPlanning = await TimeregplanningService.update(data)
        onSave()
        onHide()
        // Object.assign(model, data);
        // props.onSave(model);
        // props.onHide()
    }

    const onRemove = async () => {
        // if (model.customer > 0 && model.id > 0) {
        //     showLoading()
        //
        //     const _resp = await CustomerService.contactInUse(model.customer, model.id)
        //     if (_resp.value) {
        //         errorAlert(i18n('error_remove_contact_person_in_use'))
        //         hideLoading()
        //         return
        //     }
        //     hideLoading()
        // }
        // props.onRemove(model);
        // props.onHide()
    }


    return <XlnzDialog headerI18n='create_planning' onHide={onHide}
                       buttons={() => [
                           disabled
                               ? undefined
                               : <SaveButton key='save' labelI18n='save'
                                             disabled={isSubmitting}
                                             onClick={handleSubmit(onSubmit)}/>,
                           disabled || !model?.id
                               ? undefined
                               : <RemoveButton key='remove' onClick={e => onRemove()}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className="EditForm" style={{width: '400px'}}>
            <form>
                <FormItemProject options={projectList} required {...form}/>
                <FormItemDate name='startDate' labelI18n='start_date' {...form}/>
                <FormItemDate name='endDate' labelI18n='end_date' {...form}/>
                <FormItemText name='note' labelI18n='note' maxLength={5000} {...form}/>

            </form>
        </div>
    </XlnzDialog>

}