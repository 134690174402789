import {
    addDays as fnsAddDays,
    addMonths as fnsAddMonths,
    addSeconds as fnsAddSeconds,
    differenceInCalendarDays,
    differenceInMilliseconds,
    endOfWeek,
    format,
    getDate as fnsGetDate,
    getDaysInMonth as fnsGetDaysInMonth,
    getISOWeek,
    isAfter as fnsIsAfter,
    isBefore as fnsIsBefore,
    isWeekend as fnsIsWeekend,
    isWithinInterval as fnsIsWithinInterval,
    lastDayOfMonth,
    startOfMonth,
    startOfWeek,
} from 'date-fns'
import {sv} from "date-fns/locale";
// import {sv} from 'date-fns/esm/locale'
import {getHolidaysForYear} from './holidays'
import {isNumeric} from "./NumberUtils";
import {capitalizeFirstLetter} from "./StringUtil";

export const MONTHS_I18N_KEYS = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];


const MAX_DATE = __getDate('4000-01-01')


export const getDateObject = anyDate => __getDate(anyDate)

function __getDate(anyDate) {
    const result = __getDateUntilTestedProperly(anyDate)
    if (!(result instanceof Date)) {
        alert("Unable to parse date: '" + anyDate + "'")
        throw new Error("Unable to parse date: '" + anyDate + "'")
    }
    return result;
}

function __getDateUntilTestedProperly(anyDate) {
    //If no date
    if (!anyDate) return new Date()

    //If already a date object
    if (anyDate instanceof Date) return anyDate

    //If a string like '2022-10-01', '2022-10-01T18:03:56.311Z' etc
    if (typeof anyDate === 'string') return new Date(anyDate)

    //If number of millis since 1970
    if (isNumeric(anyDate)) return new Date(anyDate)

    console.log("isNumeric(anyDate)", isNumeric(anyDate), typeof anyDate, anyDate)
    console.log("'2022-10-01T18:38:40Z'.length", '2022-10-01T18:38:40Z'.length, anyDate.length)

    alert("Unable to parse date: '" + anyDate + "'")
    throw new Error("Unable to parse date: '" + anyDate + "'")
}

export const addDays = (date, numOfDays) => formatDate(fnsAddDays(__getDate(date), Number(numOfDays)))
export const addMonths = (date, numOfMonths) => formatDate(fnsAddMonths(__getDate(date), Number(numOfMonths)))
export const addSeconds = (time, amount) => fnsAddSeconds(__getDate(time), amount).toISOString()
export const diffInMillis = (dateLeft, dateRight) => differenceInMilliseconds(__getDate(dateLeft), __getDate(dateRight))
export const formatDate = (date = new Date()) => format(__getDate(date), 'yyyy-MM-dd')
export const formatTime = timeStr => timeStr ? new Date(timeStr).toLocaleString() : ''
export const formatTimeUtc = timeStr => timeStr ? new Date(timeStr).toISOString() : new Date().toISOString()
export const formatTimeFileSafe = (date = new Date()) => format(__getDate(date), 'yyyyMMdd_HHmmss')
export const getDayInMonth = date => fnsGetDate(__getDate(date))
export const getDayNameShort = date => capitalizeFirstLetter(format(__getDate(date), 'EEE', {locale: sv}))
export const getDayName = date => capitalizeFirstLetter(format(__getDate(date), 'EEEE', {locale: sv}))
export const getDaysBetween = (dateOne, dateTwo) => Math.abs(differenceInCalendarDays(__getDate(dateOne), __getDate(dateTwo))) + 1
export const getDaysInMonth = anyDateInMonth => fnsGetDaysInMonth(__getDate(anyDateInMonth))
export const getHolidayName = (date, includeNonBuildDays) => lookupHolidayName(formatDate(date), includeNonBuildDays)
export const getFirstDateOfMonth = date => formatDate(startOfMonth(date ? __getDate(date) : new Date()))
export const getFirstDateOfWeek = date => formatDate(startOfWeek(date ? __getDate(date) : new Date(), {locale: sv}))
export const getLastDateOfMonth = date => formatDate(lastDayOfMonth(date ? __getDate(date) : new Date()))
export const getLastDateOfYearMonth = (year, month) => formatDate(new Date(year, month, 0))
export const getLastDateOfWeek = date => formatDate(endOfWeek(date ? __getDate(date) : new Date(), {locale: sv}))
export const getMonth = date => __getDate(date).getMonth() + 1
export const getMonthNameShort = date => capitalizeFirstLetter(format(__getDate(date), 'MMM', {locale: sv}))
export const getMonthName = date => capitalizeFirstLetter(format(__getDate(date), 'MMMM', {locale: sv}))
export const getYear = date => __getDate(date).getFullYear()
export const getYearMonth = date => formatDate(__getDate(date)).substring(0, 7)
//https://www.veckonummer.top/2022
export const getWeek = date => getISOWeek(__getDate(date))
export const getWeekDayIndex = date => __getDate(date) !== undefined ? __getDate(date).getDay() : -1
export const isHoliday = (date, includeNonBuildDays) => lookupHolidayName(formatDate(date), includeNonBuildDays) !== undefined
export const isWeekend = date => fnsIsWeekend(__getDate(date))
export const isBuildDay = date => !isHoliday(date, true) && !isWeekend(date)

export const isBefore = (date, dateToCompare) => fnsIsBefore(__getDate(date), __getDate(dateToCompare))
export const isAfter = (date, dateToCompare) => fnsIsAfter(__getDate(date), __getDate(dateToCompare))
export const isWithinInterval = (date, start, end) => {
    const _start = __getDate(start)
    const _end = end ? __getDate(end) : MAX_DATE
    if (fnsIsAfter(_start, _end)) return false
    return fnsIsWithinInterval(__getDate(date), {
        start: _start,
        end: _end
    })
}
export const now = () => formatTimeUtc()
export const todayDate = () => formatDate()
export const todayMonth = () => new Date().getMonth() + 1
export const todayYear = () => new Date().getFullYear()

function lookupHolidayName(date, includeNonBuildDays) {
    const year = getYear(date)
    const holidays = getHolidaysForYear(year)
    // console.log('holidays', holidays, includeNonBuildDays)
    return holidays
        .filter(item => item.isPublicHoliday || (includeNonBuildDays && item.isNonBuildDay))
        .find(item => formatDate(item.date) === date)
        ?.name
}