import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import AccountService from "./AccountService";

const AccountEditForm = () => {
    return <SimpleEditForm
        writeRole={Role.ADMIN}
        headlineI18n='account'
        defaultValues={{
            active: true
        }}
        loadFunction={AccountService.findById}
        saveFunction={AccountService.update}
        legalCompany={true}
        initLeftFields={form => [
            <FormItemInteger key='number' name='number' labelI18n='number' required min={1}
                             max={9999} {...form}/>,
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.ACCOUNT_LIST}
    />
}

export default AccountEditForm;
