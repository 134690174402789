import React from 'react';
import {useForm} from "react-hook-form";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemText from "../../../components/EditForm/FormItemText";
import {useFilter} from "../../../components/Grid/filter";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import {formatTimeUtc} from "../../../utils/DateUtils";
import Formrowtype from "../../Forms/edit/Formrowtype";
import FormanswerService from "../FormanswerService";
import FormtypeCheck from "./types/FormtypeCheck";
import FormtypeHeader1 from "./types/FormtypeHeader1";
import FormtypeHeader2 from "./types/FormtypeHeader2";
import FormtypeInfo from "./types/FormtypeInfo";
import FormtypeNumber from "./types/FormtypeNumber";
import FormtypeRadio from "./types/FormtypeRadio";
import FormtypeSelect from "./types/FormtypeSelect";
import FormtypeText from "./types/FormtypeText";
import FormtypeTextarea from "./types/FormtypeTextarea";
import './FormanswerEditForm.scss'

export const FORMROW_KEY_PREFIX = 'formrow-'

const FormanswerEditForm = props => {
    const {
        model,
        onSave,
        defaultFormValues = {},
        onHide,
        preview = false
    } = props

    const filter = useFilter('FormanswerEditForm', [
        {name: 'showToEmployees', defaultValue: true},
        {name: 'fillable', defaultValue: true}
    ])

    const fixedMembers = [
        'name',
        'showToEmployees',
        'fillable',
        'finishTime'
    ]

    const defaultValues = {}
    fixedMembers.forEach(item => defaultValues[item] = model[item])

    if (model.id > 0) {
        model.rowList.forEach(item => {
            const key = FORMROW_KEY_PREFIX + item.formrow
            const formrow = getFormrow(key)

            // console.log("FORWOWOR", formrow)

            let value = item.value
            if (formrow.type === Formrowtype.CHECK.id && value) {
                value = true
            }

            defaultValues[key] = value
        })
    } else {
        defaultValues.name = model.form.name
        defaultValues.showToEmployees = filter.showToEmployees
        defaultValues.fillable = filter.fillable

        model.form.flattenRowList.forEach(item => {
            if (item.defaultValue.length > 0) {
                const key = FORMROW_KEY_PREFIX + item.id
                if (item.type === Formrowtype.CHECK.id) {
                    defaultValues[key] = true
                } else {
                    let _defaultValue = item.defaultValue
                    for (const defaultFormValueKey in defaultFormValues) {
                        _defaultValue = _defaultValue.replaceAll(defaultFormValueKey, defaultFormValues[defaultFormValueKey])
                    }
                    defaultValues[key] = _defaultValue
                }
            }
        })
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, watch} = form

    const formValues = watch(); // observera alla fält

    function getFormrow(key) {
        const formrowId = Number(key.replace(FORMROW_KEY_PREFIX, ''))
        const result = model.form.flattenRowList.find(item => item.id === formrowId)
        if (!result) throw new Error("Unable to find formrow with key: " + key)
        return result
    }

    const onSubmit = async data => {
        showLoading()

        filter.update('showToEmployees', data.showToEmployees)
        filter.update('fillable', data.fillable)

        const saveModel = model

        saveModel.rowList = []

        for (const key in data) {
            if (fixedMembers.indexOf(key) !== -1) {

                if (key === 'finishTime') {

                    if (data.finishTime) {
                        saveModel.finishTime = formatTimeUtc(data.finishTime)
                    } else {
                        delete saveModel.finishTime
                    }

                } else {
                    saveModel[key] = data[key]
                }


                continue
            }

            const formrow = getFormrow(key)
            let value = data[key]

            if (formrow.type === Formrowtype.CHECK.id && value) {
                value = '1'
            }

            if (value?.length > 0) {
                saveModel.rowList.push({
                    id: 0,
                    formrow: formrow.id,
                    value: value
                })
            }
        }


        console.log("SAVE FORMANSWER ", saveModel)
        const _savedModel = await FormanswerService.update(saveModel)
        onSave(_savedModel)
        onHide()
        hideLoading()
    }

    function onDelete() {
        confirmYes(async () => {
            showLoading()
            await FormanswerService.delete(model)
            onSave(model)
            onHide()
            hideLoading()
        })
    }

    function getComponent(item) {
        return <div key={item.id} style={{marginBottom: '5px'}}>
            {getComponent2(item)}
            {/*({item.key})*/}

        </div>
    }

    function getComponent2(item) {
        switch (item.type) {
            case Formrowtype.HEADER1.id:
                return <FormtypeHeader1 key={item.id} model={item}/>
            case Formrowtype.HEADER2.id:
                return <FormtypeHeader2 key={item.id} model={item}/>
            case Formrowtype.INFO.id:
                return <FormtypeInfo key={item.id} model={item}/>
            case Formrowtype.TEXT.id:
                return <FormtypeText key={item.id} model={item} {...form}/>
            case Formrowtype.TEXTAREA.id:
                return <FormtypeTextarea key={item.id} model={item} {...form}/>
            case Formrowtype.NUMBER.id:
                return <FormtypeNumber key={item.id} model={item} {...form}/>
            case Formrowtype.CHECK.id:
                return <FormtypeCheck key={item.id} model={item} {...form}/>
            case Formrowtype.RADIO.id:
                return <FormtypeRadio key={item.id} model={item} {...form}/>
            case Formrowtype.SELECT.id:
                return <FormtypeSelect key={item.id} model={item} {...form}/>
            case Formrowtype.NEW_PAGE.id:
                return <div style={{height: '20px'}}></div>
            default:
                return <div key={item.id}>ERROR: {item.name}</div>
        }
    }

    let skipCount = 0

    function getLeftButtons() {
        return preview ? [] : [
            <FormItemCheckbox key='showToEmployees' name='showToEmployees' labelI18n='show_to_employees' {...form}/>,
            <FormItemCheckbox key='fillable' name='fillable' labelI18n='fillable' {...form}/>,
            <FormItemOptionalDate key='finishTime' name='finishTime' labelI18n='mark_as_completed' {...form}/>
        ]
    }

    function getButtons() {
        return <>
            {!preview && <SaveButton onClick={handleSubmit(onSubmit)}/>}
            {
                model.id > 0 &&
                <RemoveButton onClick={onDelete}/>
            }
            <CancelButton onClick={e => onHide()}/>
        </>
    }


    function isCondictionMet(formrow) {
        // console.log("formrow", formrow)
        if (formrow.condition.length === 0) return true;

        // console.log("formValues", formValues)

        let evalStr = ''
        for (const fr of model.form.flattenRowList) {
            if (fr.key.length > 0) {
                // console.log("fr", fr)
                // const far = getFormanswerrow(fr);
                // formValues['formrow-' + far.id].replaceAll("'", "\\'")

                let value = formValues['formrow-' + fr.id] || ''

                // console.log("value", value)
                if (typeof value === 'string') {
                    value = value.replaceAll("'", "\\'")
                        .replaceAll("\n", "\\n")
                        .replaceAll("\r", "\\r");
                }
                // console.log("value", value)
                evalStr += 'const ' + fr.key + " = '" + value + "'\n"

            }
        }

        evalStr += formrow.condition
        // console.log("evalStr", evalStr)

        /*eslint-disable no-eval */
        return eval(evalStr)
    }

    return <XlnzDialog header={model.form.name}
                       leftButtons={getLeftButtons}
                       buttons={getButtons}
                       onHide={onHide}>

        <div className="EditForm Formanswer" style={{width: '900px', margin: 0}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{
                    maxHeight: 'calc(100vh - 300px)',
                    overflowY: 'auto',
                    padding: '0 10px 40px 0'

                }}>
                    <div style={{borderBottom: '1px solid #ddd', marginBottom: '10px', paddingBottom: '10px'}}>
                        <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>
                    </div>
                    {
                        model.form.flattenRowList.map((formrow, index) => {
                            if (isCondictionMet(formrow)) {
                                if (skipCount > 0) {
                                    skipCount--
                                    return undefined
                                } else {
                                    if (formrow.type === Formrowtype.ROW.id) {
                                        skipCount = formrow.value
                                        const _list = model.form.flattenRowList.slice(index + 1, index + Number(formrow.value) + 1)

                                        return <div key={formrow.id} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '20px',
                                            alignItems: "flex-end"
                                        }}>
                                            {
                                                _list.map(i => <div key={'row-' + i.id} style={{
                                                    width: '100%',
                                                    flex: 1
                                                }}>{getComponent(i)}</div>)
                                            }
                                        </div>

                                    } else {
                                        return getComponent(formrow)
                                    }
                                }
                            }
                            return undefined
                        })
                    }
                </div>
            </form>
        </div>
    </XlnzDialog>
}

export default FormanswerEditForm;
