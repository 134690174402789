import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from 'react';
import AppContext from "../../AppContext";
import {i18n} from "../../I18n/I18n";
import LocalStorageUtils, {STORAGE_PREFIX} from "../../utils/LocalStorageUtils";
import SessionStorageUtils from "../../utils/SessionStorageUtils";
import {MainButton} from "../Buttons/Button";
import Grid from "../Grid/Grid";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

const CompanySwitch = props => {
    const context = useContext(AppContext);

    const {
        onHide
    } = props

    const switchCompany = (company, newWindow) => {
        sessionStorage.removeItem(STORAGE_PREFIX + 'filter')
        LocalStorageUtils.setItem('company', company.id)

        if (newWindow) {
            const newTab = window.open('/', '_blank')
            newTab.onload = () => {
                newTab.sessionStorage.setItem(STORAGE_PREFIX + 'company', company.id)
                newTab.location.reload()
            }
            onHide()
        } else {
            SessionStorageUtils.setItem('company', company.id)
            window.location = '/'
        }
    }

    // console.log("context.user.superuser", !!context.user.superAdmin)
    const columns = [
        {header: 'ID', field: 'id', width: 70, hidden: !context.user.superAdmin},
        {
            headerI18n: 'company',
            field: 'label'
        },
        {
            headerI18n: 'open_in_a_new_window',
            field: 'open_in_a_new_window',
            body: rowData => <div style={{width: '100%'}}
                                  onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      switchCompany(rowData, true)
                                  }}>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{fontSize: '16px', color: '#666'}}/>
            </div>,
            width: 80,
            align: 'center'
        }
    ].filter(item => !item.hidden)

    return <XlnzDialog header={i18n('change_company')}
                       onHide={onHide}
                       buttons={() => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
    >
        {
            <div style={{width: '450px'}}>
                <Grid columns={columns}
                      value={context.companies}
                      heightOffset={200}
                      onRowSelect={e => {
                          switchCompany(e.data)
                      }}
                />
            </div>
        }
    </XlnzDialog>
}
export default CompanySwitch;