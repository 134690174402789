import NetUtils from "../../utils/NetUtils";

const TenderService = {
    findAll: params => NetUtils.doGet('/api/tenders', params),
    findAllSent: params => NetUtils.doGet('/api/tenders/sent', params),
    findToRemind: params => NetUtils.doGet('/api/tenders/to-remind', params),
    findUnassigned: () => NetUtils.doGet('/api/tenders/unassigned'),
    findById: id => NetUtils.doGet('/api/tenders/' + id),
    update: (model, files) => NetUtils.doPostWithFiles('/api/tenders', model, files),
    // update: model => NetUtils.doPost('/api/tenders', model),

    downloadPdf: async tenderId => NetUtils.downloadFile('/api/tenders/' + tenderId + '/pdf'),
    downloadAppendix: (tenderId, appendixId) => NetUtils.downloadFile('/api/tenders/' + tenderId + "/appendix/" + appendixId),

    findAllHowtotemplate: () => NetUtils.doGet('/api/tenders/howtotemplates'),
    updateHowtotemplate: modelList => NetUtils.doPost('/api/tenders/howtotemplates', modelList),
    deleteHowtotemplate: model => NetUtils.doDelete('/api/tenders/howtotemplates', model),

    sendByEmail: async (tenderId, toEmail, message, attachPdf) =>
        NetUtils.doPost('/api/tenders/' + tenderId + '/send-by-email',
            {
                toEmail: toEmail,
                message: message,
                attachPdf: attachPdf,
            }),

    findRowsByTender: tenderId => NetUtils.doGet('/api/tenderrows', {tender: tenderId}),
    findRowsByProject: projectId => NetUtils.doGet('/api/tenderrows', {project: projectId}),

    findOptionsByTender: tenderId => NetUtils.doGet('/api/tenderoptions', {tender: tenderId}),
    findOptionsByProject: projectId => NetUtils.doGet('/api/tenderoptions', {project: projectId}),

    createProject: id => NetUtils.doPost('/api/tenders/' + id + '/create-project'),

    downloadTendersendlogPdf: async (tenderId, tendersentlogId) =>
        NetUtils.doGet('/api/tenders/' + tenderId + '/tendersentlog/' + tendersentlogId + '/pdf-url'),

    copy: tenderId => NetUtils.doGet('/api/tenders/' + tenderId + "/copy"),
}
export default TenderService;