import {faCheckCircle, faFileLines} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useContext, useEffect, useState} from 'react';
import AppContext from "../../AppContext";
import {AddButton} from "../../components/Buttons/Button";
import DatePicker from "../../components/Date/DatePicker";
import Label from "../../components/EditForm/Label";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import ProgressIcon from "../../components/Grid/ProgressIcon";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useDialog from "../../hooks/useDialog";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {distinct, findById, sortByField} from "../../utils/ArrayUtil";
import {todayDate, todayYear} from "../../utils/DateUtils";
import NetUtils from "../../utils/NetUtils";
import {projectNumberComparator} from "../../utils/SortUtils";
import ProjectService from "./ProjectService";
import ProjectStatus from "./ProjectStatus";
import useProjectContextMenuItems from "./useProjectContextMenuItems";

const ProjectGrid = () => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()
    const pyramid = useCompanyProp(Companyprop.PYRAMID)
    const dialog = useDialog()

    const costcenterList = preload.getCostcenterList().filter(item => item.active)
    const employeeList = preload.getEmployeeList()

    const filter = useFilter('ProjectGrid', [
        {name: 'status', defaultValue: ProjectStatus.ONGOING.id},
        {name: 'date', defaultValue: todayDate()},
        {name: 'year', defaultValue: todayYear()},
        {name: 'costcenter', defaultValue: undefined, values: costcenterList},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const contextMenu = useProjectContextMenuItems({date: filter.date, dialog: dialog})

    const [list, setList] = useState([]);
    const projectLeaderList = sortByField(distinct(list.map(item => item.projectLeader)).map(id => findById(employeeList, id)), 'name')
    const filteredList = list?.filter(item => match(filter, item, 'projectLeader'))
    // const filteredList = list?.filter(item => matchCostcenter(filter, item) && match(filter, item, 'projectLeader'))

    filteredList.forEach(item => {
        item.costcenterName = findById(costcenterList, item.costcenter)?.name || ''

    })

    const load = useCallback(() => {
        showLoading()
        const params = {
            status: filter.status,
            startDate: filter.date,
            endDate: filter.date,
            year: filter.year,
            costcenter: filter.costcenter,
            search: filter.search
        }
        ProjectService.findAll(params).then(_list => {
            setList(_list);
            hideLoading()
        });
    }, [filter.status, filter.date, filter.year, filter.costcenter, filter.search])

    useEffect(() => {
        load()
    }, [load])


    function getFormStatsTooltip(formStats) {

        const completed = formStats.statList
            .filter(item => item.finishTime)
            .map(item => <div key={item.id}>{item.name}</div>)

        const remaining = formStats.statList
            .filter(item => !item.finishTime)
            .map(item => <div key={item.id}>{item.name}</div>)

        return <div>
            {
                completed.length > 0 &&
                <>
                    <div style={{fontWeight: 'bold'}}>{i18n('done')}</div>
                    {completed}
                </>
            }

            {
                remaining.length > 0 &&
                <>
                    {
                        completed.length > 0 &&
                        <div>&nbsp;</div>
                    }
                    <div style={{fontWeight: 'bold'}}>{i18n('remain')}</div>
                    {remaining}
                </>
            }

            {
                formStats.sentPercentage === 1 &&
                <div style={{marginTop: '10px', fontWeight: "bold", display: 'flex', alignItems: 'center'}}>
                    <FontAwesomeIcon fontSize={15} icon={faCheckCircle} color="white"/>&nbsp;
                    {i18n('all_documents_sent_to_customer')}
                </div>
            }
        </div>
    }

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {
            field: 'number',
            headerI18n: 'number',
            width: 100,
            mobile: true,
            sortFunction: (p1, p2) => projectNumberComparator(p1.number, p2.number)
        },
        // {field: 'objectNo', header: 'Objekt', width: 60, mobile: true, hidden: !pyramid},
        {field: 'projectLeaderName', headerI18n: 'project_leader', mobile: true},
        {field: 'customerName', headerI18n: 'customer', mobile: true},
        {
            field: 'markup',
            headerI18n: 'reference_no',
            body: GridTemplates.onelineString,
            excelBody: GridTemplates.excelBody,
            width: 100
        },
        {field: 'address', headerI18n: 'address', mobile: true},
        {field: 'city', headerI18n: 'city', mobile: true},
        {field: 'startDate', headerI18n: 'start_date', width: 80},
        {field: 'endDate', headerI18n: 'end_date', width: 80},
        {field: 'costcenterName', headerI18n: 'costcenter', hidden: costcenterList?.length === 0},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
        {
            field: 'formProgress', header: <FontAwesomeIcon fontSize={15} icon={faFileLines}/>,
            body: rowData => <ProgressIcon
                progress={rowData.formStats.completionPercentage}
                forceDone={rowData.formStats.sentPercentage === 1}
                tooltip={getFormStatsTooltip(rowData.formStats)}
            />,
            excelExclude: true,
            width: 30,
            align: 'center',
            hidden: !['jonas@xlnz.tech', 'daniel.torin@enter.se', 'marcus.knutsen@enter.se'].includes(context.user.email)
        },
    ].filter(item => !item.hidden)

    const leftFilters = [
        roles.hasRole(Role.PROJECT_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={ProjectStatus.values()}
                // optionLabel='labelMultiple'
                // optionValue='value'
                onChange={e => filter.update('status', e.value)}
                style={{width: '150px'}}/>
        </div>
    ]

    if (filter.status === ProjectStatus.COMING.id || filter.status === ProjectStatus.ONGOING.id) {
        leftFilters.push(<div>
            <label className="label">{i18n('date')}</label>
            <DatePicker value={filter.date} onChange={date => filter.update('date', date)}/>
        </div>)
    }

    if (filter.status === ProjectStatus.COMPLETED.id || filter.status === ProjectStatus.CANCELLED.id) {
        leftFilters.push(<div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>)
    }

    if (costcenterList?.length > 0) {
        leftFilters.push(<div>
            <Label valueI18n='costcenter'/>
            <XlnzDropdown
                value={filter.costcenter}
                options={costcenterList}
                optionLabel='label'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('costcenter', e.value)}
                style={{width: '250px'}}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='project_leader'/>
        <XlnzDropdown
            value={filter.projectLeader}
            options={projectLeaderList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('projectLeader', e.value)}
            style={{width: '200px'}}/>
    </div>)


    // const contextMenu = [
    //     ContextMenuItem({
    //         i18n: 'project_summary', icon: Icon.LOG,
    //         command: item => dialog.show(<ProjectSummaryDialog projectId={item.id}/>)
    //
    //     }),
    //     ContextMenuItem({
    //         i18n: 'edit_tender', icon: Icon.TENDER,
    //         command: item => navigate('/tenders/' + item.tender),
    //         hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tender,
    //     }),
    //     ContextMenuItem({
    //         i18n: 'do_invoice', icon: Icon.INVOICE,
    //         command: item => navigate('/invoices/0?project=' + item.id),
    //         hidden: row => !roles.hasRole(Role.INVOICE_WRITE)
    //     }),
    // ]

    const menuItems = [{
        label: i18n('generate_rent'),
        faicon: Icon.GENERATE,
        command: async () => {
            showLoading()
            await ProjectService.generateRentForAllCompanies()
            hideLoading()
        },
        hidden: !roles.hasRole(Role.SUPERADMIN)
    }]

    if (pyramid) {
        menuItems.push({
            label: i18n('fetch_from_pyramid'),
            faicon: Icon.PLUGIN,
            command: async item => {
                showLoading()
                const _resp = await NetUtils.doGet('/api/pyramid/sync-projects')
                await preload.load(PreloadType.CUSTOMER_LIST)
                await load()
                infoAlert(_resp.value)
                hideLoading()
            }
        })
    }

    return <>
        <Grid
            id='ProjectGrid'
            labelI18n='projects'
            icon={Icon.PROJECT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            contextMenu={contextMenu}
            menuItems={menuItems}
            expandableField='parent'
        />
        {dialog.render()}
    </>
}

export default ProjectGrid;
